import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import DynamicImage from "../components/dynamic-image"


const ParMums = () => (
  <Layout>
    <SEO title="Home" />

	<div className="par-mums-header">
		<DynamicImage filename={"par-mums-header.jpg"} />
	</div>

    <div className="pa4 pa5-m pa7-l pv5-l">
		<h1>Mūsu risinājums</h1>
    	<p>
			SIA CHALETE ir uzņēmums, kurš piedāvā dažādus risinājumus koksnes apdarei interjeram un eksterjeram.<br/>
			Viss sākās ar to ka klienti meklē nestandarta un jaunus risinājumus savām vajadzībām aizsargāt, apdarīt, vai izlabot koksnes defektus.<br/><br/>
			Mūsdienās ļoti liela uzmanība tiek pievērsta inovatīviem un dabai draudzīgiem materiāliem. Klienti izvēlas materiālus, lai tajos būtu pēc iespējas mazāk ķīmijas, lai tie būtu ekoloģiski, draudzīgi cilvēkam un apkārtējai videi, lai pie materiālu izmantošanas koks vizuāli un estētiski labi izskatītos un pildītu tam paredzēto uzdevumu. Lai izvēlētie materiāli kalpotu ilgi un tos būtu viegli kopt, un lai pats apstrādes process neaizņemtu daudz laika. Ja svarīga ir kvalitāte un jauns risinājums – mums ir dažādi risinājumi!
			<br/><br/>
			Ar speciālistu palīdzību, kuru uzkrātā pieredze mērāma vairākās paaudzēs mēs piedāvāsim Jums labākos pieejamos materiālus no mūsu partneriem AMONN Color un OLI Lacke
			<br/><br/>
			Sadarbībā ar Eiropā pazīstamiem ražotājiem, kuri veiksmīgi realizē dažādus projektus katru gadu, tagad arī mēs varam piedāvāt Klientiem šos produktus un individuālus risinājumus Latvijā. Tās ir dažādas eļļas koksnei interjeram un eksterjeram. No mazām galdniecībām līdz industriālai ražošanai. Dažādas lazūras eksterjeram un interjeram. Dažādus kombinētus apdares risinājumus Maksimālai koksnes aizsardzībai pret dažādiem laika apstākļiem. Materiālus, lai atjaunotu un uzlabotu koksnes izskatu, kurš ieguvis pelēku nokrāsu Saules UV staru ietekmē . Krāsas lai  pasargātu koksni pret dažādiem extrēmiem laika apstākļiem (Lignex Prodeck, Amolis). Produktu ar kura palīdzību var notīrīt instrumentus vai iekārtas no sveķiem vai netīrumiem, tas ir Aquaprofi Reiniger.<br/><br/> Specifiskus materiālus ar kuriem var izlabot koksnes defektus. Īpašs apdares materiāls ar kuru var uzlabot koksnes izskatu (Lignex Renova Plus), kurš laika gaitā ieguvis pelēku nokrāsu. Speciāls Hermētiķis ar kuru var pasargāt koksnes galus, tas ir Aquaprofi Siegel, lai koksnes detaļas laika gaitā nesaplaisā un neuzņem mitrumu. Dažādi risinājumi arī no dizaineru līnijas HydroLignex Design ar specifiskiem toņiem. 
			Mēs turpināsim pilnveidot mūsu programmu un ar laiku pievienot jaunus produktus. Ja rodas interese pār kādu no produktiem atliek vienīgi pajautāt…
    	</p>
    </div>

  </Layout>
)

export default ParMums
